/**  =====================
      Data Tables css start
==========================  **/

.table {
    td,
    th {
        //border-top: 1px solid $theme-border;
        white-space: nowrap;
        //padding: 1.05rem .75rem;
    }

    thead th {
        border-bottom: 1px solid $theme-border;

    }

    tbody + tbody {
        border-top: 2px solid $theme-border;
    }
}
// Border versions

.table-bordered {
    border: 1px solid $theme-border;

    td,
    th {
        border: 1px solid $theme-border;
    }
}
// Zebra-striping

.table-striped {
    tbody tr:nth-of-type(2n+1) {
        background-color: transparentize($primary-color,0.95);
    }
}
// Hover effect

.table-hover {
    tbody tr {
        &:hover {
            background-color: transparentize($primary-color,0.95);
        }
    }
}
// Table backgrounds

.table {
    .thead-dark {
        th {
            color: #fff;
            background-color: $dark-color;
            border-color: darken($dark-color,10%);
        }
    }
}

.table-dark {
    color: #fff;
    background-color: $dark-color;

    td,
    th,
    thead th {
        border-color: darken($dark-color,10%);
    }

    &.table-striped {
        tbody tr:nth-of-type(odd) {
            background-color: darken($dark-color,2%);
        }
    }

    &.table-hover {
        tbody tr {
            &:hover {
                background-color: darken($dark-color,5%);
            }
        }
    }
}
// fixed header position

table.dataTable.fixedHeader-floating {
    top: 0 !important;
}
/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > {
    .active > {
        span,a {
            background-color: $primary-color;
            border-color: $primary-color;

            &:focus,
            &:hover {
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
    }

    li > {
        a,
        span {
            color: #222;
        }
    }
}

.footable-details.table,
.footable.table {
    margin-bottom: 0;
}

table {
    &.footable > tfoot > tr.footable-paging > td > span.label {
        margin-bottom: 0;
    }

    &.footable-paging-center > tfoot > tr.footable-paging > td {
        padding-bottom: 0;
    }
}
.table-columned >{
    tbody >{
        tr >{
            td{
                border: 0;
                border-left: 1px solid $theme-border;
            }
            th{
                border: 0;
            }
        }
    }
}


/**====== Foo-table css end ======**/
/**====== Data Tables css end ======**/
