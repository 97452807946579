body {
  line-height: 1.5;

  td, th {
    font-family: "Open Sans", sans-serif;
  }
}
header {
  width: 100%;
}

.form-control{
  height: auto;
}

.form-select {
  font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #6F6F6F;
    background-color: #F7F7F7;
}

.border-less-image-gallery ks-modal-gallery .plain-container .image {
  border: none;
}

.datta-accordion {
  .card-header{
    .btn{
      padding: 0;
    }
  }
}

.h-back-user-list {
  color: $primary-color !important;
}

@media only screen and (max-width: 575px) {
  .pcoded-header .dropdown .dropdown-menu {
    left: 0 !important;
  }

  wizard.vertical .vertical a {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .filter-bar .card-task .task-board {
    float: none;
    margin-top: 25px;
  }
}

.pcoded-header .dropdown.show:before {
  left: -10px !important;
}

div.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
}

#timer {
  color: #ffffff;
  b {
    font-size: 24px;
  }
}

pre code{
  width: auto;
  min-width: 100%;
  margin-left: -80px !important;
}


// .btn.btn-sm {
//   padding: 3px 12px;
//   border-radius: .15rem;
// }

// .btn.btn-lg {
//   padding: 12px 25px;
//   border-radius: .35rem;
// }


/*.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a{
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
  }
}*/

.alert-light {
  background-color: #f8f9fa !important;
}

.bg-primary {
  background-color: #A71F23 !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-button {
 color: #A71F23;
 font-size: 24px;
 font-weight: 900;
}

.accordion-button:not(.collapsed){
  color: initial;
  background-color: transparent;
}

.accordion-button:focus {
  box-shadow: none;
}

.otp .form-control {
  font-size: 36px;
  font-weight: 900;
  line-height: 24px;
  background-color: #454548;
  text-transform: uppercase;
  color: #fff;
  padding: 0.375rem 0.75rem;
  text-align: center;
}

.text-primary {
  color: #A71F23 !important;
}
.input-group.otp {
  background-color: transparent;
}

.br-8 {
  border-radius: 8px;
}

.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  -webkit-box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  background-color: $primary-color;
    border-color: $primary-color;
}

.animate-bottom {
  position: relative;
  animation: animatebottom 1s;
}

.animate-right {
  position: relative;
  animation: animateright 1s;
}

.icon-background {
  color: #F3981E;
}

.icon-color {
  color: #411312;
}

.icon-background-sm {
  color: #fff;
}

.icon-color-sm {
  color: #F3981E;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}


//stepper
.stepper-header .steps .step.active .step-bullet {
  background-color:#C8C7C7 !important;
  color: #111 !important;
  border: none !important;
}

.stepper-header .steps .step.completed .step-bullet {
  background-color: #EAAB21 !important;
  color: #111 !important;
  border: none !important;
}

.stepper-header .steps .step .step-bullet {
  background-color: #fff !important;
  border: 1px solid #111 !important;
  color: #111 !important;
  font-weight: 500;
}

.tracker-container{
  box-shadow: 64px 54px 134px -27px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid #000;
}

.w-15{
  width: 15%;
}

.w-30{
  width: 30%;
}
.modal-content {
  border-radius: 25px !important;
}
//vertical scroll
.vertical.carousel .carousel-control {
  bottom: auto;
  width: 100%;
  height: 15%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0, rgba(0,0,0,0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 100%);
}
.vertical.carousel .carousel-control.right {
top: auto;
bottom: 0;
background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.vertical.carousel .carousel-control .glyphicon {
-webkit-transform: rotate(90deg);
        transform: rotate(90deg);
}
.vertical.carousel .carousel-indicators {
bottom: auto;
top: 50%;
left: auto;
right: 10px;
width: 14px;
margin: 0;
-webkit-transform: rotate(90deg);
        transform: rotate(90deg);
}

.vertical.carousel .carousel-inner {
  padding-right: 1rem;
}
.vertical.carousel .carousel-inner > .item {
left: 0;
top: 0;
}
.vertical.carousel .carousel-inner > .item > img {
width: 100%;
}
.vertical.carousel .carousel-inner > .item.next,
.vertical.carousel .carousel-inner > .item.active.right {
-webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
top: 0;
}
.vertical.carousel .carousel-inner > .item.prev,
.vertical.carousel .carousel-inner > .item.active.left {
-webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
top: 0;
}
.vertical.carousel .carousel-inner > .item.next.left,
.vertical.carousel .carousel-inner > .item.prev.right,
.vertical.carousel .carousel-inner > .item.active {
-webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
top: 0;
}
.vertical.carousel .carousel-inner > .active,
.vertical.carousel .carousel-inner > .next.left,
.vertical.carousel .carousel-inner .prev.right {
top: 0;
}
.vertical.carousel .carousel-inner > .next,
.vertical.carousel .carousel-inner > .active.right {
top: 100%;
left: 0;
}
.vertical.carousel .carousel-inner > .prev,
.vertical.carousel .carousel-inner > .active.left {
top: -100%;
left: 0;
}

.vertical.carousel .carousel-control-next-icon {
  transform: rotate(90deg) !important;
}

.vertical.carousel .carousel-control-prev-icon {
  transform: rotate(90deg) !important;
}

.vertical.carousel .carousel-control-next {
  align-items: end !important;
  height: min-content;
  bottom: 0;
  right: 0;
  position: absolute;
  margin-right: 0.8rem !important;
}

.vertical.carousel .carousel-control-prev {
  align-items: start !important;
  right:0;
  top:0;
  left: unset;
  height: min-content;
  margin-right: 0.8rem !important;
}

.vertical.carousel .carousel-indicators [data-bs-target] {
  border: 3px solid black !important;
  background-color: #000;
  border-radius: 50%;
  height: auto;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
    top:auto;
}


.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
    transform: translate3d(0,-100%,0) !important;
    -webkit-transform: translate3d(0,-100%,0) !important;
    -moz-transform: translate3d(0,-100%,0) !important;
    -ms-transform: translate3d(0,-100%,0) !important;
    -o-transform: translate3d(0,-100%,0) !important;
  }

  .carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
    transform: translate3d(0,100%,0) !important;
    -webkit-transform: translate3d(0,100%,0) !important;
    -ms-transform: translate3d(0,100%,0) !important;
    -moz-transform: translate3d(0,100%,0) !important;
    -o-transform: translate3d(0,100%,0) !important;
  }


.chat-block {
  background-image: linear-gradient(RGB(131, 132, 134), RGB(0, 0, 0));
color: #fff !important;
}

.bold {
  font-weight: bold;
}

.btn-count {
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  padding: 5px 12px;
}

.input-count {
  border: none;
  text-align: center;
  width: 50px;
}
