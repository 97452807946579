/* You can add global styles to this file, and also import other style files */
@import "scss/variables";

/* fonts-icon */
// @import "scss/fonts/fontawesome/scss/fontawesome";
// @import "scss/fonts/feather/iconfont";

@import "scss/fonts/datta/datta-icons.css"; /** custom font icons - datta **/
@import "scss/general";
@import "scss/generic";
@import "scss/mixins/function";

/* important element */
@import "scss/menu/menu-lite";
@import "scss/widget/widget";

/* basic elements */
@import "scss/theme-elements/theme-elements";

/* advance-other elements */
@import "scss/other/other";

/* third party modules style*/
@import "scss/plugins/plugins";

@import "scss/custom";


.cdk-overlay-pane{
  z-index: 10000 !important;
}
.cdk-overlay-container { z-index: 100000; }
::ng-deep .cdk-overlay-container {
  z-index: 10000 !important;
}
.select2-container--open {
  z-index: 9999999 !important;
}
.btn-circle.btn-xl {
  border-radius: 60px;
  font-size: 15px;
  text-align: center;
}

@import '~@angular/material/theming';
@mixin custom-stepper-theme() {
  .mat-horizontal-stepper-header {
    pointer-events: none;
  }
}
